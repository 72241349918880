import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`ES Server - 1.7.0`}</h2>
    <ol>
      <li parentName="ol">{`Deploy “blank” `}<inlineCode parentName="li">{`Windows Server 2016`}</inlineCode>{` Virtual Machine:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Ensure the VM is up to date with Microsoft Patches`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Navigate to `}<a parentName="li" {...{
          "href": "https://provision-portal.stage.bddevops.com/"
        }}>{`Provision Portal`}</a></li>
      <li parentName="ol">{`Login with BD Creditials - `}<em parentName="li">{`Global ID and Password`}</em></li>
      <li parentName="ol">{`Navigate to your Customer Account`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Your Customer Account will be your Global ID and Name.`}</li>
      <li parentName="ul">{`If you do not have one, please visit the Hermes Deployment documenation to kick of a deployment.`}</li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Search for your`}<inlineCode parentName="li">{`Name`}</inlineCode>{` or `}<inlineCode parentName="li">{`Global ID`}</inlineCode></li>
    </ol>
    <p>{`// Edits in progress…`}</p>
    <ol>
      <li parentName="ol">{`Customer account is created by DevOps in AWS so that this customers deployment data and device management is segregated`}</li>
      <li parentName="ol">{`BD Authorized User(s) will be able to add/update Parameters for Deployment`}</li>
      <li parentName="ol">{`The Customer Account page will show information about previous Server deployments (if applicable), Station deployments, Settings and Customer Information`}</li>
      <li parentName="ol">{`User will be able to create a specific Deployment for an ES Server and/or ES MedStation deployment workflow`}</li>
    </ol>
    <ul>
      <li parentName="ul"><em parentName="li">{`Note: Creating a deployment is only a “staging” activity and does not execute any action on the Customers end`}</em></li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Deployments are uniquely identified with a Deployment Name and ID`}</li>
      <li parentName="ol">{`Deployments require using Customer based Parameters that are stored, securely, for the deployment such as Service User Credentials and SQL Server name(s)`}</li>
      <li parentName="ol">{`When a Customer has provided BD with the necessary VM(s) for Server deployments or has approved downtime for MedStation deployments the User can take action`}</li>
      <li parentName="ol">{`For Server deployments, the User must select the correct deployment to gather the Deployment ID and copy it along with the Customers ID`}</li>
      <li parentName="ol">{`The User will visit, either locally or on the Customer provided VM, `}<a parentName="li" {...{
          "href": "https://provision.bddevops.com/"
        }}>{`BD Provisioning`}</a>{` and enter the correct information`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`For Development environments, please use `}<a parentName="li" {...{
          "href": "https://provision.stage.bddevops.com/"
        }}>{`BD Provisioning - Stage`}</a></li>
    </ul>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`This will allow the user to download an `}<inlineCode parentName="li">{`AWS SSM Agent`}</inlineCode>{` installer, custom built for that deployment`}</li>
    </ol>
    <ul>
      <li parentName="ul"><em parentName="li">{`Note: This allows a User to install the agent ‘x’ amount of times authorized by the deployment creation`}</em></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      